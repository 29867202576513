<template>
	<div class="ex-norge">
        <div class="ex-norge-headcontent" style="background-image: url('images/bg_norge.jpg');">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <h1 class="text-white">Our Services</h1><br /><br />
                        <p class="text-white">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis eros rutrum, commodo lectus in, tincidunt lorem. Ut id turpis eu orci condimentum blandit. Nulla rutrum justo nec tortor venenatis, quis cursus leo cursus.
                        </p><br /><br />
                        <a href="#" class="ex-btn-norge">
                            Get Services
                            <span>
                                <i class="fas fa-arrow-right"></i>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="ex-norge-centercontent">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-4 ex-services-cover">
                        <div class="ex-norge-circle-services ex-bg-circle-one">
                            <i class="fas fa-cogs"></i>
                        </div>
                        <h3>Easy Use</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis eros rutrum, commodo lectus in, tincidunt lorem.
                        </p>
                    </div>
                    <div class="col-12 col-md-4 ex-services-cover">
                        <div class="ex-norge-circle-services ex-bg-circle-two">
                            <i class="fas fa-bolt"></i>
                        </div>
                        <h3>Fast Setup</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis eros rutrum, commodo lectus in, tincidunt lorem.
                        </p>
                    </div>
                    <div class="col-12 col-md-4 ex-services-cover">
                        <div class="ex-norge-circle-services ex-bg-circle-tri">
                            <i class="fas fa-user"></i>
                        </div>
                        <h3>24/7 Support</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis eros rutrum, commodo lectus in, tincidunt lorem.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="ex-norge-detail">
            <hr />
            <div class="container">
                <div class="ex-goTo-link">
                    <div class="row">
                        <div class="col-12 col-md-4" align="center">
                            <a href="#get_one">
                                <i class="fas fa-cogs"></i> Easy Use
                            </a>
                        </div>
                        <div class="col-12 col-md-4 border-right border-left" align="center">
                            <a href="#get_two">
                                <i class="fas fa-bolt"></i> Fast Setup
                            </a>
                        </div>
                        <div class="col-12 col-md-4" align="center">
                            <a href="#get_tri">
                                <i class="fas fa-user"></i> 24/7 Support
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <hr />

            <div class="ex-norge-morecontent">
                <div class="container">
                    <div id="get_one" class="row mb-5">
                        <div class="col-12 col-md-6" align="center">
                            <img src="/images/image_one.png" class="img-fluid" />
                        </div>
                        <div class="col-12 col-md-6 d-flex align-items-center">
                            <div>
                                <h2>Easy Use</h2><br />
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis eros rutrum, commodo lectus in, tincidunt lorem. Ut id turpis eu orci condimentum blandit. Nulla rutrum justo nec tortor venenatis, quis cursus leo cursus.
                                </p><br />
                                <a href="#" class="ex-btn-norge">
                                    Get Services
                                    <span>
                                        <i class="fas fa-arrow-right"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div id="get_two" class="row mb-5">
                        <div class="col-12 col-md-6 d-flex align-items-center">
                            <div>
                                <h2>Fast Setup</h2><br />
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis eros rutrum, commodo lectus in, tincidunt lorem. Ut id turpis eu orci condimentum blandit. Nulla rutrum justo nec tortor venenatis, quis cursus leo cursus.
                                </p><br />
                                <a href="#" class="ex-btn-norge">
                                    Get Services
                                    <span>
                                        <i class="fas fa-arrow-right"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div class="col-12 col-md-6" align="center">
                            <img src="/images/image_two.png" class="img-fluid" />
                        </div>
                    </div>
                    <div id="get_tri" class="row">
                        <div class="col-12 col-md-6" align="center">
                            <img src="/images/image_tri.png" class="img-fluid" />
                        </div>
                        <div class="col-12 col-md-6 d-flex align-items-center">
                            <div>
                                <h2>24/7 Support</h2><br />
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis eros rutrum, commodo lectus in, tincidunt lorem. Ut id turpis eu orci condimentum blandit. Nulla rutrum justo nec tortor venenatis, quis cursus leo cursus.
                                </p><br />
                                <a href="#" class="ex-btn-norge">
                                    Get Services
                                    <span>
                                        <i class="fas fa-arrow-right"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>    
    export default {
        components: {
        },
    };
</script>

<style>
    *{
        scroll-behavior: smooth !important;
    }

    .ex-norge-headcontent {
        /* background: url('/images/bg_norge.jpg'); */
        background-repeat: no-repeat;
        background-size: cover;
        padding: 200px 0 120px 0;
    }

    .ex-norge-centercontent {
        padding: 50px 0 50px 0;
    }

    .ex-norge-morecontent {
        padding: 50px 0 50px 0;
    }

    .ex-btn-norge {
        width: auto;
        height: auto;
        position: relative;
        display: inline-block;
        padding: 15px 70px 15px 20px;
        margin: 0;
        border-width: 1px;
        border-style: solid;
        border-radius: 150px;
        cursor: pointer;
        outline: none;
        -webkit-backface-visibility: hidden;
        vertical-align: middle;
        -webkit-transition: all .5s ease;
        transition: all .5s ease;
        white-space: normal;
        -webkit-box-shadow: 0px 3px 5px 0px rgb(20 39 82 / 3%);
        box-shadow: 0px 3px 5px 0px rgb(20 39 82 / 3%);
        text-decoration: none;
        text-align: left;
        font-size: 1em;
        line-height: 22px;
        font-weight: 700;
        background-color: #fff;
        border-color: #e8eaee;
        color: #142752;
        visibility: visible;
        animation-delay: 0.8s;
        animation-name: fadeInUp;
    }

    .ex-btn-norge:hover span {
        background-color: #73B4C6;
        color: #fff;
    }

    .ex-btn-norge:hover {
        background-color: #3D8297;
        border-color: #3D8297;
        -webkit-transition: all .5s ease;
        transition: all .5s ease;
        visibility: visible;
        animation-delay: 0.8s;
        animation-name: fadeInUp;
        color: #fff;
        text-decoration: none;
    }

    .ex-btn-norge span {
        width: 40px;
        height: 40px;
        display: inline-block;
        position: absolute;
        right: 5px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        border-radius: 50%;
        font-size: inherit;
        text-align: center;
        background-color: rgba(37,204,247,.25);
        color: #001266;
    }

    .ex-btn-norge span i {
        display: inline-block;
        font-size: 16px;
        line-height: 40px;
    }

    .ex-services-cover {
        text-align: center;
    }

    .ex-norge-circle-services {
        width: 80px;
        height: 80px;
        border-radius: 100%;
        /* background-color: #3D8297; */
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }

    .ex-norge-circle-services i {
        font-size: 25px;
        color: #FFFFFF;
    }

    .ex-services-cover h3 {
        color: #00306E;
    }

    .ex-services-cover p {
        font-size: 13px;
    }

    .ex-bg-circle-one {
        background-color: #43D07E;
    }

    .ex-bg-circle-two {
        background-color: #F0664E;
    }
    
    .ex-bg-circle-tri {
        background-color: #439BD0;
    }

    .ex-goTo-link a, .ex-goTo-link i{
        font-size: 18px;
        color: #00306E;
        font-weight: bold;
        text-decoration: none;
    }

    .ex-goTo-link a:hover {
        text-decoration: none;
    }

    .ex-goTo-link i {
        margin-right: 10px;
    }
</style>