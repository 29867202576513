import axios from 'axios';
import { createStore } from 'vuex';


import { getLocalUser } from '../helpers/auth';

const user = getLocalUser();

export default createStore({
  state : {
		welcomeMessage : 'Welcome to the DinHMS admin',
		currentUser: user,
		isLoggedIn: !!user,
		loading: false,
		auth_error: null,
		customers: [],
		customersz: [],
		persons: [],
		language: [],
		contentTemplate: [
							{val:'home.index', text:'home.index'},
							{val:'home.content', text:'home.content'},
							{val:'home.contact', text:'home.contact'},
							{val:'login', text:'Login'},
							{val:'home.product', text:'home.product'},
							{val:'home.Tjenester', text:'home.Tjenester'},
							{val:'order.listorder', text:'order.cistorder'},
							{val:'order.createorder', text:'order.createorder'}
						],
		companiClassification: [
							{val:'1', text:'Root'},
							{val:'2', text:'Root 2'},
							{val:'3', text:'Do not display'},
							{val:'4', text:'Custommer'},
							{val:'6', text:'Customer 2'},
							{val:'7', text:'Partner'},
							{val:'8', text:'Partner 2'},
						],
		companiType: [
							{val:'1', text:'Supplier manager'},
							{val:'2', text:'Supplier Terapeut'},
							{val:'3', text:'Client manager'},
							{val:'4', text:'Client employee'},
						],
		monthList: [
							{val:'1', text:'January','langstring':'idJanuary'},{val:'2', text:'February','langstring':'idFebruary'},
							{val:'3', text:'Mars','langstring':'idMarch'}, {val:'4', text:'April','langstring':'idApril'},
							{val:'5', text:'Mai','langstring':'idMay'},{val:'6', text:'Juni','langstring':'idJune'},
							{val:'7', text:'Juli','langstring':'idJuly'},{val:'8', text:'August','langstring':'idAugust'},
							{val:'9', text:'September','langstring':'idSeptember'},{val:'10', text:'Oktober','langstring':'idOctober'},
							{val:'11', text:'November','langstring':'idNovember'},{val:'12', text:'Desember','langstring':'idDecember'},
						],
		budget: [
                    { value: 1, text: '$0-$25.000'},
                    { value: 2, text: '$25.000-$50.000'},
                    { value: 3, text: '$25.000-$100 000'},
                    { value: 4, text: '$100.000-$250.000'},
                    { value: 5, text: '$250.000-$500.000'},
                    { value: 6, text: '$500.000-$1.000.000'},
                    { value: 7, text: '$1.000.000-$2.500.000'},
                    { value: 8, text: '$2.500.000-$5.000.000'},
                    { value: 9, text: '$5.000.000-$10.000.000'},
                    { value: 10, text: '$10.000.000-$25.000.000'},
                    { value: 11, text: '$25.000.000+'}
                ],
        prioritas : [
			{ value: 1, text: 'High'},
			{ value: 2, text: 'Medium'},
			{ value: 3, text: 'Low'}
		]

	},

    mutations : {
		login(state){
			state.loading = true,
			state.auth_error = null;
		},
		loginSuccess(state, payload){
			state.auth_error = null;
			state.isLoggedIn = true;
			state.loading = false;
			state.currentUser = Object.assign({}, payload.user, {token: payload.access_token});

			localStorage.setItem("user", JSON.stringify(state.currentUser));
		},
		loginFailed(state, payload){
			state.loading = false,
			state.auth_error = payload.error;
		},
		logout(state){
			localStorage.removeItem('user');
			state.isLoggedIn = false;
			state.currentUser = null;
		},
		updateCustomers(state, payload) {
			state.customersz = payload;
		},
		listPersons(state, payload) {
			state.persons = payload;
		},
		languages: (state, newValue) => {
			state.language = newValue
		}

	},

    getters : {
		welcome(state){
			return state.welcomeMessage;
		},

		templates(state){
			return state.contentTemplate;
		},

		isLoading(state){
			return state.loading;
		},

		isLoggedIn(state){
			return state.isLoggedIn;
		},

		currentUser(state){
			return state.currentUser;
		},

		checkout(state){
			return state.checkout;
		},

		authError(state){
			return state.auth_error;
		},

		customers(state){
			return state.customersz;
		},

		persons(state){
			return state.persons;
		},

		companyClass(state){
			return state.companiClassification;
		},

		companyType(state){
			return state.companiType;
		},

		monthList(state){
			return state.monthList;
		},

		budget(state){
			return state.budget;
		},

		prioritas(state){
			return state.prioritas;
		},

		languages(state){
			return state.language;
		},

		getLang(state){
			return state.lang;
		}
	},
	actions: {
		login(context){
			context.commit('login');
		},
		async addRandomNumber(context) {
            let data = await axios.get("https://www.admin.empatix.asia/api/hitung")
            context.commit('addToCounter', data.data)
        },
		getCustomers(context) {
			var data = axios.get('https://www.admin.empatix.asia/api/companies/show-all')
			.then((response) => {
				context.commit('updateCustomers', response);
				return response.data
			})
			.catch((err) => {
				console.log(err);
			})
			return data;
		},
		getPersons(context) {
			axios.get('https://www.admin.empatix.asia/api/user/list-persons')
			.then((response) => {
				context.commit('listPersons', response.data.data);
			})
			.catch((err) => {
				console.log(err);
			})
		},
		getDataCompany({commit}, newValue){
			commit('language', newValue)
			var data = axios.get('https://www.admin.empatix.asia/api/company/detail/'+newValue)
			.then((response) => {
				return response.data
			})
			.catch((error) =>{
				alert('Error')
				console.log(error)
				this.mute = false;
			});

			return data;
		},
		getNodes({commit}, newValue){
			commit('language', newValue)
			// console.log(newValue, "newValue")
			// let lang = state.lang
			var data = axios.get('https://www.admin.empatix.asia/api/user/list-nodes')
			.then((response) => {
				return response.data.data
			})
			.catch((error) => {
				alert('Error')
				console.log(error)
				this.mute = false;
			});

			return data;
		},
		storeNodes(context){
			axios.get('https://www.admin.empatix.asia/api/user/store-nodes')
			.then((response) => {
				context.commit('storeNodes', response.data.data);
			})
			.catch((err) => {
				console.log(err);
			})
		},
		getNodeById({commit}, newValue){
			commit('language', newValue)
			var data = axios.get('https://www.admin.empatix.asia/api/front/node/'+newValue)
			.then((response) => {
				return response.data.data
			})
			.catch((error)=>{
				alert('Error')
				console.log(error)
				this.mute = false;
			});
			return data;
		},
		languages: ({commit}, newValue) =>{
			commit('languages',newValue.sess)
			var dataa = axios.post('https://www.admin.empatix.asia/api/languagestring/arr/'+newValue.sess, {'language_string':newValue})
			.then((response) => {
				return response.data.language
			})
			.catch((error)=>{
				alert('Error')
				console.log(error)
				this.mute = false;
			});
			return dataa;
		}
	}
    
})
