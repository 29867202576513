import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/pages/Home.vue'
import ContactUs from '../views/pages/ContactUs.vue';
import Content from '../views/pages/Content.vue';
import NorgePage from '../views/pages/NorgePage.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/pages/contactus',
        name: 'ContactUs',
        component: ContactUs
    },
    {
        path: '/pages/:title',
        name: 'Content',
        component: Content
    },
    {
        path: '/norge',
        component: NorgePage
    },
    
    
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
router.beforeEach((to) => {
    if(to.name == undefined){
        document.title = `Empatix Asia`
    }
    else {
        document.title = `Empatix Asia | ${to.name}`
    }
    
})

export default router
