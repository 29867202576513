<template>
            <div id="main">
                <Navmenu/>
                <router-view></router-view>
                <vue-progress-bar></vue-progress-bar>
                <Footer/>
            </div>
</template>

<script>
import Navmenu from './views/layouts/Navmenu.vue';
import Footer from './views/layouts/Footer.vue';
export default {
  name: 'App',
  components: {
    Navmenu, Footer
  },
   mounted () {
            this.test()
        },
        methods:{
            start () {
                this.$Progress.start()
            },
            set (num) {
                this.$Progress.set(num)
            },
            increase (num) {
                this.$Progress.increase(num)
            },
            decrease (num) {
                this.$Progress.decrease(num)
            },
            finish () {
                this.$Progress.finish()
            },
            fail () {
                this.$Progress.fail()
            },
            test () {
                this.$Progress.start()
                this.$Progress.finish()  
            }
        },
  }
</script>

