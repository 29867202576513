<template>
	<div class="footer">
		<footer>
			<div class="container">
				<div class="row">
					<div class="col-md-4">
						<span class="copyright">Copyright &copy; Empatix Asia 2022 - 2023</span>
					</div>
					<div class="col-md-4">
						<ul class="list-inline social-buttons">
							<li class="list-inline-item">
								<a href="https://www.linkedin.com/in/guttorm/" target="_blank" aria-label="linked-in">
								<i class="fab fa-linkedin-in"></i>
								</a>
							</li>
						</ul>
					</div>
					<div class="col-md-4">
						<div class="address">
							<span>{{top}}</span><br/>
							<span>{{mid}}</span><br/>
							<span>{{bot}}</span>
						</div>
					</div>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
	export default {
		name: 'app-footer',

		data() {
            return {
				top: '',
				mid: '',
				bot: '',
            }
        },
		
		mounted() {
			this.getDataMenu()
        },

		methods:{
			getDataMenu() {

				this.axios.get('menu/footer')
                .then(response => {
					this.top = response.data.top
					this.mid = response.data.mid
					this.bot = response.data.bot
                })
			},
		}
	}
</script>

<style>
.address{
	font-size: 90%;
	font-family: 'Montserrat', Arial, sans-serif;
}
</style>

