<template>
	<div class="contentdata">
		{{ reverseData() }}
		<section class="head-content">
			<div class="container">

				<div v-if="datacontent.content1">
				<div class="row">
					<template v-if="datacontent.img1">
						<div class="col-md-6 col-sm-6 col-xs-12">
							<img :src="datacontent.img1" class="img-fluid img-ctn float-left" alt="" />
						</div>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<div class="grid-content" v-html="datacontent.content1"></div>
						</div>
					</template>
					<template v-else>
						<div class="col-md-12 col-sm-12 col-xs-12">
							<div class="grid-content" v-html="datacontent.content1"></div>
						</div>
					</template>
				</div>
				<br /><hr /><br />
				</div>

				<div v-if="datacontent.content2">
				<div class="row">
					<template v-if="datacontent.img2">
						<div class="col-md-6 col-sm-6 col-xs-12">
							<div class="grid-content" v-html="datacontent.content2"></div>
						</div>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<img :src="datacontent.img2" class="img-fluid img-ctn float-right" alt="" />
						</div>
					</template>
					<template v-else>
						<div class="col-md-12 col-sm-12 col-xs-12">
							<div class="grid-content" v-html="datacontent.content2"></div>
						</div>
					</template>
				</div>
				<br /><hr /><br />
				</div>

				<div v-if="datacontent.content3">
				<div class="row">
					<template v-if="datacontent.img3">
						<div class="col-md-6 col-sm-6 col-xs-12">
							<img :src="datacontent.img3" class="img-fluid img-ctn float-left" alt="" />
						</div>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<div class="grid-content" v-html="datacontent.content3"></div>
						</div>
					</template>
					<template v-else>
						<div class="col-md-12 col-sm-12 col-xs-12">
							<div class="grid-content" v-html="datacontent.content3"></div>
						</div>
					</template>
				</div>
				<br /><hr /><br />
				</div>

				<div v-if="datacontent.content4">
				<div class="row">
					<template v-if="datacontent.img4">
						<div class="col-md-6 col-sm-6 col-xs-12">
							<div class="grid-content" v-html="datacontent.content4"></div>
						</div>
						<div class="col-md-6 col-sm-6 col-xs-12">
							<img :src="datacontent.img4" class="img-fluid img-ctn float-right" alt="" />
						</div>
					</template>
					<template v-else>
						<div class="col-md-12 col-sm-12 col-xs-12">
							<div class="grid-content" v-html="datacontent.content4"></div>
						</div>
					</template>
				</div>
				<br /><hr /><br />
				</div>

			</div>
		</section>
	</div>
</template>
<style>
#form1-m {
	display: block;
}
</style>
<script>
	import _ from 'lodash';
	export default {
		name: 'content-data',
		data(){
			return {
				language_strings : ['idP66H_0'],
				reslang: [],
				currentroute: "",
				datacontent:{},
				answer:'',
				fullPage: false,
			}
		},
		watch: {
			currentroute: function () {
				this.debouncedGetAnswer()
			}
		},
		created: function () {
			this.debouncedGetAnswer = _.debounce(this.getdatacontent, 500)
		},
		mounted() {
			this.getDataLang()
		},
		methods: {
			getDataLang() {
				if (this.$storage.getStorageSync('langSessionID')) {
					var langid = this.$storage.getStorageSync('langSessionID');
					} else {
						langid = 'id';
					}
        this.language_strings['sess'] = langid;

        var datalangs = this.$store.dispatch('languages', this.language_strings)

        datalangs.then(response => {
			this.reslang =  response

        })
		.catch(error => {
			console.log(error, 'Got nothing for this user except bad news.')
        })

    },
    getdatacontent: function() {
		let loader = this.$loading.show({
			container: this.fullPage ? null : this.$refs.formContainer,
			canCancel: false,
			loader: 'spinner',
			width: 96,
			height: 96,
			backgroundColor: '#ffffff',
			opacity: 1,
			zIndex: 999,
			isFullPage: true,
			transition: 'fade',
		});
		var param = this.$route.params.title
		if(this.$route.params.lang !== undefined){
			param = this.$route.params.lang+'/'+this.$route.params.title
		}
		this.axios.get('pages/'+param)
		.then(response => {
			this.datacontent = response.data.data;
			loader.hide()
			})
			.catch((error)=>{
				console.log(error)
			});
    },
    reverseData: function () {
		this.currentroute = this.$route.params.title;
    }
},
}
</script>

<style>
.img-ctn{
	width:480px
}
</style>