import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store'
import {initialize} from './helpers/general'
import router from './router'

import Vue3Storage from "vue3-storage"
import VueProgressBar from "@aacassandra/vue3-progressbar"
import VueLoading from 'vue-loading-overlay'
import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import 'vue-loading-overlay/dist/vue-loading.css'


axios.defaults.baseURL = 'https://www.admin.empatix.asia/api/'
initialize(store, router);


const options = {
  color: '#d0400f',
  failedColor: '#874b4b',
  thickness: '5px',
  transition: {
    speed: '0.1s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueAxios, axios)
app.use(VueLoading)
app.use(Vue3Storage)
app.use(VueProgressBar, options)
app.use(BootstrapVue3)
app.mount('#app')
