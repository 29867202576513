<template>
	<div class="contentdata">
        <section id="contact">
            <div class="container">
            <h2 class="section-heading text-uppercase">{{reslang.idTcHp11}}</h2>
            <div class="row">
                <div class="col-lg-5 mb-lg-0 mb-5 mx-auto text-left">
                    <form @submit.prevent="submit">
                    <div class="form-group">
                        <label for="email">Email address:</label>
                        <input type="email" class="form-control" v-model="form.email" id="email" required>
                    </div>
                    <div class="form-group">
                        <label for="text">Name:</label>
                        <input type="text" class="form-control" v-model="form.name" id="name" required>
                    </div>
                    <div class="form-group">
                        <label for="comment">Message:</label>
                        <textarea class="form-control" rows="5" id="content" v-model="form.content" required></textarea>
                    </div>

                    <vue-recaptcha
                        :loadRecaptchaScript="true"
                        @verify="onCaptchaVerified"
                        @expired="onCaptchaExpired"
                        sitekey="6LdwlbIUAAAAACYeM8Dnj9fCTo6ZSCJHzso5ruv1">
                    </vue-recaptcha>
                    <span v-show="error" class="text-danger">*Please Submit reCaptcha</span>
                    <br/>
                    <button type="submit" class="btn btn-info" id="submit">Submit</button>
                    </form>

                </div>
                <div class="col-lg-6 mx-auto">
                    <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <i class="fas fa-map fa-3x pb-4"></i>
                    <p>{{company['address']}}</p>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <i class="fas fa-mobile-alt fa-3x pb-4"></i>
                    <p>Phone: {{company['phone']}}</p>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <i class="far fa-paper-plane fa-3x pb-4"></i>
                    <p>{{company['email']}}</p>
                    </div>
                    </div>
                </div>
            </div>
            </div>
    </section>
	</div>
</template>
<style>
#form1-m {
	display: block;
}
</style>
<script>
    import { VueRecaptcha } from 'vue-recaptcha';

	export default {
        name: 'content-data',
        components: { VueRecaptcha },
		data(){
			return {
                language_strings : ['idTcHp1','idTcHp2','idTcHp3','idTcHp4','idTcHp5','idTcHp6','idTcHp7','idTcHp8',
                'idTcHp9','idTcHp10','idTcHp11'],
                reslang: [],
                form: {},
                company: [],
                error: false,
                isSubmitting: false,
                fullPage: false,
			}
		},
		watch: {

		},
		created: function () {
		},
		mounted() {
            this.getDataLang()
            this.getdata()
		},
		methods: {
            submit() {
                if(this.isSubmitting == false){
                    this.error = true
                }else{
                    document.getElementById("name").disabled = true;
                    document.getElementById("content").disabled = true;
                    document.getElementById("email").disabled = true;
                    document.getElementById("submit").disabled = true;

                    this.axios.post('contactus', this.form)
                    .then((response) => {
                        alert(response.data.data)
                        document.getElementById("name").disabled = false;
                        document.getElementById("content").disabled = false;
                        document.getElementById("email").disabled = false;
                        document.getElementById("submit").disabled = false;
                        this.form = {}
                    })
                    .catch((error)=>{
                        console.log(error)
                    });
                }
            },

            onCaptchaVerified(){
                this.isSubmitting = true
            },

            onCaptchaExpired() {
                this.$refs.recaptcha.reset();
            },
 
            getDataLang() {
            if (this.$storage.getStorageSync('langSessionID')) {
              var langid = this.$storage.getStorageSync('langSessionID');
            } else {
              langid = 'id';
            }
            
            let loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: false,
                loader: 'spinner',
                width: 96,
                height: 96,
                backgroundColor: '#ffffff',
                opacity: 1,
                zIndex: 999,
                isFullPage: true,
                transition: 'fade',
                });
                
                this.language_strings['sess'] = langid;
                    
                var datalangs = this.$store.dispatch('languages', this.language_strings)

                datalangs.then(response => { 
                    this.reslang =  response
                    loader.hide()
                })
                .catch(error => {
                    console.log(error)
                    loader.hide()
                })
            },
            
            getdata(){
                let loader = this.$loading.show({
					container: this.fullPage ? null : this.$refs.formContainer,
					canCancel: false,
					loader: 'spinner',
					width: 96,
					height: 96,
					backgroundColor: '#ffffff',
					opacity: 1,
					zIndex: 999,
					isFullPage: true,
					transition: 'fade',
                    });
				
				this.axios.get('contactus')
				.then((response) => {
					this.company = response.data.company
					loader.hide()
				})
				.catch((error)=>{
					console.log(error)
					loader.hide()
				});
            }
        },
    }
</script>

<style>
.img-ctn{
	width:480px
}
.grecaptcha-badge {
    visibility: hidden !important;
}

</style>