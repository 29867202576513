<template>
	<div class="navmenu">
		<nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
			<div class="container" style="max-width:1200px">
				<router-link :to="`/`" class="navbar-brand js-scroll-trigger hidden-sm-down">
				<img src="/assets/project/assets/img/brand.png" width="290" alt="Empatix"/>
				</router-link>
				<router-link :to="`/`" class="navbar-brand js-scroll-trigger hidden-lg-up">
				<img src="/assets/project/assets/img/brand.png" width="220" alt="Empatix"/>
				</router-link>
				<button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">Menu
					<i class="fas fa-bars"></i>
				</button>
			
			<div v-for="(lang, index) in lang_id" :key="index" class="float-right">
			<button class="btn btn-sm btn-link ml-1" @click="change_lang(lang.title)"><img :src="'/images/'+lang.title+'.png'" width="22" height="22" alt="language"></button>
			</div>

			<div class="collapse navbar-collapse" id="navbarResponsive">
				<ul class="navbar-nav text-uppercase ml-auto" style="font-size:12px">
					<li v-for="(menu, index) in datamenu" :class="menu.subchild == 1 ? 'nav-item dropdown': 'nav-item' " :key="index">
						<router-link :to="`/pages/${menu.alias}`" :class="'nav-link link'" v-if="menu.subchild == 0">
							{{ reslang[menu.lang_id] == null ? menu.title : reslang[menu.lang_id] }}
						</router-link>
						<a class="nav-link dropdown-toggle" data-toggle="dropdown" v-if="menu.subchild == 1">
							{{ reslang[menu.lang_id] == null ? menu.title : reslang[menu.lang_id] }}
							<span class="caret"></span>
						</a>
						
						<ul class="dropdown-menu" v-if="menu.subchild == 1">
							<li v-for="(menus, indexs) in menu.child" :class="'nav-item'" :key="indexs">
								<router-link :to="`/pages/${menus.alias}`" class="nav-sublink">
									{{ reslang[menus.lang_id] == null ? menus.title : reslang[menus.lang_id] }}
								</router-link>
							</li>
						</ul>
					</li>
				</ul>
			</div>
			
		</div>
	</nav>

</div>
</template>

<script>
	export default {
		name: 'nav-menu',
		data() {
            return {
                active: "",
                datamenu: [],
                reslang: [],
				menu_langid : '',
				lang_id: [],
                language_string : ['idSave'],
                classmenu: "transparent"
            }
        },
		mounted() {
			this.getDataMenu()
        },
        methods: {
			langsess(id){
				this.axios.post('langsession/arr/'+id)
                .then(resp => {
					if (this.$storage) {
						if (this.$storage.getStorageSync('langSessionID')) {
							this.$storage.setStorageSync('langSessionID', resp.data.getLangSession)
							} else {
								this.$storage.setStorageSync('langSessionID', resp.data.getLangSession)
								}
								} else {
									this.$storage.setStorageSync('langSessionID', resp.data.getLangSession)
									}
									window.location.href = this.$route.fullPath
                })
                .catch(error => {
                    console.log(error);
                })
            },
            logout() {

                this.axios.post('auth/logout')
                .then((response) => {
					console.log(response);
                  this.$store.commit('logout');
                  this.$router.push('/log-in');
                })
                .catch((err) => {
                  err("Wrong email or password");
                })
            },
            getDataMenu() {
				
				if (!this.$storage) {
					this.$storage.setStorageSync('langSessionID', "id")	
					}
				if(this.$storage.getStorageSync('langSessionID') == undefined){
					this.$storage.setStorageSync('langSessionID', "id")
				}
				
				this.menu_langid = this.$storage.getStorageSync('langSessionID')
				
				this.axios.get('menu/list/1?langid='+this.menu_langid)
                .then(response => {
					
                    this.datamenu = response.data.data
					var datalangids = [];
					
					this.lang_id = response.data.lang_id
					
					for (var x = 0; x < this.datamenu.length; x++) {
						var param = this.datamenu[x].lang_id;
						datalangids.push(param)
						}
						this.language_string = datalangids;
                   
                    this.language_string['sess'] = this.$storage.getStorageSync('langSessionID');
					var datalangs = this.$store.dispatch('languages', this.language_string)
					datalangs.then(response => {
						this.reslang = response
						})
					.catch((error) => {
						console.log(error, 'Got nothing for this user except bad news.')
						})
					
                })
			},
			
			change_lang(lang_id){
				this.$storage.setStorageSync('langSessionID', lang_id.toLowerCase())
				window.location.replace("/")
			}
        },
        computed: {
            currentUser() {
                return this.$store.getters.currentUser;
            }
        }
	}
    
</script>