<template>
	<div class="home-content">

		<header class="masthead">
			<div class="container">
				<div class="intro-text">
					<div class="intro-lead-in">{{reslang.idTcHp1}}</div>
			</div>
				<div class="row intro-grid">
					<div class="col-md-4 col-sm-4 col-xs-12">
						<div class="media media-grid">
						<img src="/assets/project/img/icon/plan.png" class="align-self-start mr-3" alt="solution">
						<div class="media-body">
							<h5>{{reslang.idTcHp2}}</h5>
							<a href="#">{{reslang.idTcHp3}}</a>
						</div>
						</div>
					</div>
					<div class="col-md-4 col-sm-4 col-xs-12">
						<div class="media media-grid">
						<img src="/assets/project/img/icon/puzzle.png" class="align-self-start mr-3" alt="solution">
						<div class="media-body">
							<h5>{{reslang.idTcHp4}}</h5>
							<a href="#">{{reslang.idTcHp5}}</a>
						</div>
						</div>
					</div>
					<div class="col-md-4 col-sm-4 col-xs-12">
						<div class="media media-grid">
						<img src="/assets/project/img/icon/computer.png" class="align-self-start mr-3" alt="solution">
						<div class="media-body">
							<h5>{{reslang.idTcHp6}}</h5>
							<a href="#">{{reslang.idTcHp7}}</a>
						</div>
						</div>
					</div>
					</div>
					</div>
					</header>

		<!-- OmOss -->
		<Header/>
		<section id="OmOss">
			<div class="container">
				<div class="row">
					<div class="col-lg-12 text-center">
						<h2 class="section-heading text-uppercase">{{reslang.idAboutUs}}</h2>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 col-sm-6 col-xs-12">
						<img :src="about.image1" class="img-fluid float-left" alt="about" /> 
					</div>
					<div class="col-md-6 col-sm-6 col-xs-12">
						<div class="grid-content" v-html="about.content1">

						</div>
						<button class="btn btn-info float-right" @click="linkto(about.alias)">{{reslang.idTcHp9}}</button>
					</div>
				</div>
			</div>
		</section>

		<section id="slider">
			<div class="container">
				<h2 class="section-slider text-uppercase">{{reslang.idOngPP}}</h2>
				<div id="demo" class="carousel slide" data-ride="carousel">
					<div class="carousel-inner">
						<div class="carousel-item active">
							<div class="row d-flex align-items-center">
								<div class="col-md-6 col-sm-6 col-xs-12">
									<img :src="ongoing.image1" class="img-fluid" alt="Los Angeles">				
								</div>
								<div class="col-md-6 col-sm-6 col-xs-12">
									<div class="grid-content" v-html="ongoing.content1">

									</div>				  
								</div>
							</div>
						</div>
						<div class="carousel-item">
							<div class="row d-flex align-items-center">
								<div class="col-md-6 col-sm-6 col-xs-12">
									<img :src="ongoing.image2" class="img-fluid" alt="Los Angeles">				
								</div>
								<div class="col-md-6 col-sm-6 col-xs-12">
									<div class="grid-content" v-html="ongoing.content2">
									
									</div>				  
								</div>
							</div>
						</div>
						<div class="carousel-item">
							<div class="row d-flex align-items-center">
								<div class="col-md-6 col-sm-6 col-xs-12">
									<img :src="ongoing.image3" class="img-fluid" alt="Los Angeles">				
								</div>
								<div class="col-md-6 col-sm-6 col-xs-12">
									<div class="grid-content" v-html="ongoing.content3">

									</div>				  
								</div>
							</div>
						</div>
						<div class="carousel-item">
							<div class="row d-flex align-items-center">
								<div class="col-md-6 col-sm-6 col-xs-12">
									<img :src="ongoing.image4" class="img-fluid" alt="Los Angeles">				
								</div>
								<div class="col-md-6 col-sm-6 col-xs-12">
									<div class="grid-content" v-html="ongoing.content4">

									</div>				  
								</div>
							</div>
						</div>
					</div>

					<a class="carousel-control-prev" href="#demo" data-slide="prev" aria-label="previous">
						<div class="carousel-circle">
							<span class="carousel-control-prev-icon"></span>
						</div>
					</a>
					<a class="carousel-control-next" href="#demo" data-slide="next" aria-label="next">
						<div class="carousel-circle">
							<span class="carousel-control-next-icon"></span>
						</div>
					</a>
				</div>
			</div>
		</section>

		<section class="banner-bg"></section>
		<section id="contact">
			<div class="container">
				<div class="row">
					<div class="col-lg-12 text-center">
						<h2 class="section-heading text-uppercase">{{reslang.idTcHp11}}</h2>
					</div>
				</div>
				<div class="row">
				<div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
					<i class="fas fa-map fa-5x pb-4"></i>
					<p>{{company['address']}}</p>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
					<i class="fas fa-mobile-alt fa-5x pb-4"></i>
					<p>Phone: {{company['phone']}}</p>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
					<i class="far fa-paper-plane fa-5x pb-4"></i>
					<p>{{company['email']}}</p>
				</div>
				</div>
				</div>
		</section>
	</div>
</template>
<script>
	import Header from '../layouts/Header.vue';
	export default {
		name: 'home-content',
		components: {Header},
		
		data(){
            return {
				language_strings : ['idTcHp1','idTcHp2','idTcHp3','idTcHp4','idTcHp5','idTcHp6','idTcHp7','idTcHp8',
				'idTcHp9','idOngPP','idTcHp11', 'idAboutUs'],
				reslang: [],
				ongoing: {},
				about: {},
				company: [],
				fullPage: false,
            }
        },
        mounted() {
			this.getDataLang()
			this.getdata()
        },
        methods: {
          getDataLang() {
            if (this.$storage.getStorageSync('langSessionID')) {
              var langid = this.$storage.getStorageSync('langSessionID');
            } else {
				langid = 'id';
            }
            
            this.language_strings['sess'] = langid;
                
            var datalangs = this.$store.dispatch('languages', this.language_strings)

            datalangs.then(response => { 
				this.reslang =  response
            })
			.catch((error) => {
				console.log(error, 'Got nothing for this user except bad news.')
			})
			},
			getdata(){
				let loader = this.$loading.show({
					container: this.fullPage ? null : this.$refs.formContainer,
					canCancel: false,
					loader: 'spinner',
					width: 96,
					height: 96,
					backgroundColor: '#ffffff',
					opacity: 1,
					zIndex: 999,
					isFullPage: true,
					transition: 'fade',
					});
					var langid = this.$storage.getStorageSync('langSessionID')
				
				this.axios.get('home/'+langid)
				.then((response) => {
					this.ongoing = response.data.ongoing
					this.about = response.data.about
					this.company = response.data.company
					loader.hide()
				})
				.catch((error)=>{
					console.log(error)
					loader.hide()
				});
			},

			linkto(link){
				this.$router.push({ path: '/pages/'+link })
			}
           
        }
	}
	
</script>
